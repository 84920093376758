/* eslint-disable @typescript-eslint/no-explicit-any */
import { PiniaSharedState } from 'pinia-shared-state'

export default defineNuxtPlugin((nuxtApp: any) => {
  nuxtApp.$pinia.use(
    PiniaSharedState({
      enable: false,
      initialize: false,
      type: 'localstorage'
    })
  )
})
